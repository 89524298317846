<template>
  <div>
    <h3>{{ categoryA.labelName }}</h3>
    <b-list-group>
      <b-list-group-item
        active
      >
        <div class="d-flex justify-content-between">
          <div class="">
            講座一覧
          </div>
          <div class="">
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
            />
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in options.categoryB"
        :key="index"
      >
        <div class="d-flex">
          <div class="text-primary text-font-L ml-1">
            {{ item.labelName }}
          </div>
          <div class="ml-2 text-font-L">
            {{ item.memo }}
          </div>
        </div>
        <div class="text-right">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="show(item)"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            詳細
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
  },
  directives: {
  },
  props: {
    categoryA: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { router } = useRouter()
    const show = item => {
      router.push({
        path: '/teacher/lessonregistration-list',
        query: {
          categoryA: props.categoryA.value,
          categoryB: item.value,
        },
      })
    }
    return {
      show,
    }
  },
}
</script>
