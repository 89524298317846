<template>
  <LessonRegistration />
</template>

<script>
import LessonRegistration from '@/components/teacher/lessonRegistration/LessonRegistration.vue'

export default {
  components: {
    LessonRegistration,
  },
}
</script>
