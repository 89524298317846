<template>
  <div class="space-basic">
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <h2>
      <feather-icon
        icon="EditIcon"
        size="24"
        class="text-primary"
      />
      登録サービス一覧
    </h2>
    <b-alert
      variant="primary"
      show
      class=""
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <div class="">
            <b-avatar
              size="40"
              src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
              variant="light-primary"
            />
          </div>
          <div class="ml-1">
            登録したサービスを確認できます。講座を編集する場合は｢詳細｣ボタンを押してください。
          </div>
        </div>
      </div>
    </b-alert>
    <hr>
    <div class="mt-2">
      <LessonList
        :category-a="categoryA[0]"
        :options="options"
      />
    </div>
    <div class="mt-4 mb-4">
      <LessonList
        :category-a="categoryA[1]"
        :options="options"
      />
    </div>
    <div class="text-white">
      <small>lesson registration list</small>
    </div>
  </div>
</template>

<script>
import {
  BAlert, BAvatar, BBreadcrumb,
} from 'bootstrap-vue'
import LessonList from '@/components/teacher/lessonRegistration/components/LessonListA.vue'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'

export default {
  components: {
    BAlert,
    BAvatar,
    LessonList,
    BBreadcrumb,
  },
  setup() {
    const {
      categoryA,
      options,
    } = useLesson()
    const breadcrumbItems = [
      {
        text: 'ダッシュボード',
        to: { name: 'teacher-dashboad' },
        icon: 'HomeIcon',
      },
      {
        text: '登録サービス一覧',
        active: true,
      },
    ]
    return {
      categoryA,
      options,
      breadcrumbItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.title_ {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(93, 83, 204);
}
.title_:hover {
  color: rgb(152, 151, 173);
}
.card_ {
  width: 100%;
  margin: 1% 0%;
}
.title_banner {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(13, 4, 116);
  margin: 2% 0 1% 0;
}
.subtitle_banner {
  font-size: 1rem;
  color: rgb(13, 4, 116);
}
.commentSpace {
  height: 140px;
}
.htmlText {
  font-size: 0.8rem;
}
</style>
